import React from 'react';

import logo from '../../theme/assets/logo_af_pro.svg';
import config from '../../config'

const Footer = () => {
    return (
        <footer className={'footer text-center bg-primary-darker'}>
            <div className="container">
                <img className="footer-logo" src={logo} alt="Logo Autofact Pro" />
                <p>Todos los derechos reservados &copy; - Contacto: soporte@autofactpro.com - {config.app.version} <br/> Contacto: soporte@autofactpro.com</p>
            </div>
        </footer>
    )
}

export default Footer;
