import {endpoints} from '../config';
import Axios from 'axios';


const API_MANAGEMENT = endpoints.af_management_api.url;

// Default config options
const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};
// Create instance
let axiosInstance = Axios.create(defaultOptions);
// Set the AUTH token for any request
axiosInstance.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

class BackendApiService {

  static async getSupplierAsignament(userId) {
    const response = await axiosInstance.get(`${API_MANAGEMENT}supplier/${userId}/assigments`);
    return response;
  }

  static async getAsignamentItems(userId, asignamentId, role) {
    const response = await axiosInstance.get(`${API_MANAGEMENT}${role}/${userId}/assigments/${asignamentId}/items`);
    return response;
  }
  
  static async validateQr(userId,asignamentId,url,role) {
    const response = axiosInstance.put(`${API_MANAGEMENT}${role}/${userId}/assigments/${asignamentId}`,{url});
    return response;
  }

  /**APPROVER */

  static async getApproverAsignament(userId) {
    const response = await axiosInstance.get(`${API_MANAGEMENT}approver/${userId}/assigments`);
    return response;
  }
}

export default BackendApiService;
