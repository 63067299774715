import React, {Component} from 'react';
import {BrowserRouter, Switch} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'alertifyjs/build/css/alertify.css';

import PrivateRoute from '../../components/private-route/PrivateRoute';
import PropsRoute from '../../components/props-route/PropsRoute';



import {MainLayout} from '../../theme/index';
import Supplier from '../supplier/Supplier'
import Home from '../home/Home';
import Detail from '../sharedComponents/Detail';
import Login from '../login/Login';
import Logout from '../logout/Logout';
import AuthService from '../../service/AuthService';
import Approver from '../approver/Approver'

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoggedIn: true,
      userRole: "",
      userId: ""
  };
  this.setIsAuthenticated = this.setIsAuthenticated.bind(this);
  this.setUserRole = this.setUserRole.bind(this);
  this.setUserId = this.setUserId.bind(this);
  this.setIsLoading = this.setIsLoading.bind(this);
}

  setIsAuthenticated(isLoggedIn) {
    this.setState({isLoggedIn});
  }

  setUserRole(userRole) {
    this.setState({userRole})
  }

  setUserId(userId) {
    this.setState({userId})
  }

  setIsLoading(isLoading){
    this.setState({isLoading});
  }

  componentWillMount() {
    AuthService.getAuthData().then(authData => {
        if (authData) {
            this.setIsAuthenticated(true);
            this.setUserRole(authData.role);
            this.setUserId(authData.userId)
        }
        else {
            this.setIsAuthenticated(false);
        }
    });
}

  render () {
    return (
      <BrowserRouter>
          <MainLayout isLoggedIn={this.state.isLoggedIn} role={this.state.userRole} userId={this.state.userId} isLoading={this.state.isLoading}>
              <Switch>
                  <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/" exact component={Home} />
                  <PropsRoute path="/login" exact component={Login} setIsAuthenticated={this.setIsAuthenticated} setUserRole={this.setUserRole} setUserId={this.setUserId} setIsLoading={this.setIsLoading} isLoading={this.state.isLoading} />
                  <PropsRoute path="/logout" exact component={Logout} setIsAuthenticated={this.setIsAuthenticated} setUserRole={this.setUserRole} setUserId={this.setUserId} setIsLoading={this.setIsLoading}/>
                  <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/supplier" exact component={Supplier} role={this.state.userRole} userId={this.state.userId} setIsLoading={this.setIsLoading}/>
                  <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/supplier/:managementId" exact component={Detail} role={this.state.userRole}  userId={this.state.userId} setIsLoading={this.setIsLoading}/>                  
                  <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/approver" exact component={Approver} role={this.state.userRole} userId={this.state.userId} setIsLoading={this.setIsLoading}/>
                  <PrivateRoute isLoggedIn={this.state.isLoggedIn} path="/approver/:managementId" exact component={Detail} role={this.state.userRole}  userId={this.state.userId} setIsLoading={this.setIsLoading}/>
              </Switch>
          </MainLayout>
      </BrowserRouter>
    );
  }
}

export default App;
