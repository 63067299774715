import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


class Home extends Component {

  render() {
    return (
      <div>home</div>
    );
  }
}

export default Home;
