import React, { Component } from 'react';
import {Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {NavLink as RRNavLink, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Footer from '../../components/footer/Footer';
import logo from '../assets/logo_af_pro.svg';

import LoadingOverlay from 'react-loading-overlay';

class MainLayout extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.state = {
            isOpen: false,
            dropdownOpen: false,
            emp_nombre: null
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleDropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        return (
            <div className="wrapper">
                <Navbar color="dark" dark expand="md" className={'bg-primary-darker'}>
                    <NavbarBrand to="/" tag={RRNavLink}>
                        <img src={logo} alt="Autofact Pro Logo" />
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggle}/>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <div className="ml-auto">
                            <Nav navbar>
                                <div className="navbar-menu">
                                    {
                                        this.props.role === 'supplier' ?
                                            <NavItem>
                                                <NavLink to="/supplier" activeClassName="active" tag={RRNavLink}>Proveedores</NavLink>
                                            </NavItem>
                                        :
                                        this.props.role === 'approver' ?
                                            <NavItem>
                                                <NavLink to="/approver" activeClassName="active" tag={RRNavLink}>Aprobadores</NavLink>
                                            </NavItem>
                                        :
                                            <span></span>
                                    }
                                </div>
                                <div className="navbar-session">
                                    {
                                        this.props.isLoggedIn ?
                                            <NavItem className="navbar-emp">
                                                <NavLink>
                                                    <FontAwesomeIcon icon="building" />
                                                    <span className="emp-name">Usuario</span>
                                                </NavLink>
                                            </NavItem>
                                        :
                                            <span></span>
                                    }
                                    <NavItem>
                                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                            <DropdownToggle caret nav>
                                                <FontAwesomeIcon icon="user"/>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                {this.props.isLoggedIn ?
                                                    <div>
                                                        <DropdownItem>
                                                            <Link to="/logout" className="dropdown-link">
                                                                <FontAwesomeIcon icon="sign-out-alt" />
                                                                Cerrar Sesión
                                                            </Link>
                                                        </DropdownItem>
                                                        {/* <DropdownItem>
                                                            <Link to="/preferencias" className="dropdown-link">
                                                                <FontAwesomeIcon icon="user-cog"/>Preferencias
                                                            </Link>
                                                        </DropdownItem> */}
                                                    </div>
                                                    :
                                                    <DropdownItem>
                                                        <Link to="/login" className="dropdown-link">
                                                        <FontAwesomeIcon icon="sign-in-alt" />
                                                        Iniciar Sesión
                                                        </Link>
                                                    </DropdownItem>
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    </NavItem>
                                </div>
                            </Nav>
                        </div>

                    </Collapse>
                </Navbar>
                <div className={this.props.isLoggedIn?'main-container':'main-containerMod'}>
                    {this.props.children}
                </div>
                <div className={this.props.isLoading ? 'loading-overlay' : ''}>
                    <LoadingOverlay
                        className="loading"
                        active={this.props.isLoading}
                        spinner
                        fadeSpeed={100}
                        text='Cargando...'
                        />
                </div>
                <Footer />
            </div>
        );
    }
}

export default MainLayout;
