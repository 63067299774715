import React, { Component } from "react";
import QrReader from 'react-qr-reader'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import alertify from 'alertifyjs';

import apiBackend from '../../service/BackendApiService';

class TableDetail extends  Component {
  
  constructor(props){
    super(props);
    this.state = {
      result : 'Sin Lectura',
      rows:{}
    }
  }

  componentDidUpdate(previousProps){
    if (previousProps.rows !== this.props.rows){
      let rows = this.getRows(this.props.rows);
      this.setState ({
        rows : rows
      });
    }
  }

  getRows(rows=[]){
    let out = {};
    rows.forEach(ele =>{
      out[ele.id] = {...ele}
    })
    return out;
  }


  handleScan = async data => {
    if (data) {
      if(data !== this.state.result){
        this.props.setIsLoading(true)
        this.setState({
          result: data
        });
        try {
          const updateItem = await apiBackend.validateQr(this.props.userId,this.props.assignametId,data,this.props.role);
          if(updateItem && updateItem.data.status === 'OK'){
            this.setState(prevState => {
              let rows = {...prevState.rows};
              if(rows[updateItem.data.data.id]){
                rows[updateItem.data.data.id].status = updateItem.data.data.status
              }
              return {rows}
            })
          }else{
            alertify.error("No se pudo validar un documento")
          }
        }catch (error) {
          // if (error.response) {
          //     console.log(error.response.data);
          //     console.log(error.response.status);
          //     console.log(error.response.headers);
          // } else if (error.request) {
          //     console.log(error.request);
          // } else {
          //     console.log('Error', error.message);
          // }
          alertify.error(error.toString())
        }
        this.props.setIsLoading(false)
      }
    }
  }
  handleError = err => {
    console.error(err)
  }

  render() {
    if(this.state.rows){
      return (
        <div className="alignCenter">
          <div>
            <QrReader class="d-flex justify-content-center"
              delay={300}
              onError={this.handleError}
              onScan={this.handleScan}
              style={{ width: '30%', margin: 'auto'}}
            />
            <p>{this.state.result}</p>
        </div>
          <h1 className="title">Elementos</h1>
          <Paper className="container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Titulo</TableCell>
                  <TableCell>Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(this.state.rows).map( element  => (
                  <TableRow key={this.state.rows[element].id}>
                    <TableCell component="th" scope="row">
                      {this.state.rows[element].id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {this.state.rows[element].title}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {this.state.rows[element].status.label}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
      )
    }
  };
}

export default TableDetail;