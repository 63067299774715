import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import AuthService from "../../service/AuthService";

class Logout extends Component {

    componentWillMount() {
        AuthService.logout();
        this.props.setIsAuthenticated(false);
        this.props.setUserId("");
        this.props.setUserRole("");
    }

    render() {
        return (
            <div>
                Login out...
                <Redirect to={'/'}/>
            </div>
        );
    }
}

export default Logout;
