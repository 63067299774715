import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

class TableManagement extends  Component {

  render() {
    return (
      <div className="alignCenter">
        <h1 className="title" >Mis Tramites</h1>
        <Paper className="container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.rows.map(({ id, title, status, timestamp}) => (
                <TableRow key={id}>
                  <TableCell component="th" scope="row">
                    {id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {title}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {status.label}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {timestamp}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Link to={this.props.role === 'supplier' ? `/supplier/${id}` : this.props.role === 'approver' ? `/approver/${id}` : `/` }>
                      Ver
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    )
  };
}

export default TableManagement;