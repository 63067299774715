const configJson = require('./config-imported');
const version = require('./version');

const endpoints = {
    af_management_api:{
        url:configJson.REACT_API_MANAGEMENT_URL || process.env.REACT_API_MANAGEMENT_URL || 'https://kzkta2n6le.execute-api.us-east-1.amazonaws.com/latest/v1/'
    }
};

const formats = {
    date: 'DD-MM-YYYY HH:mm:ss',
    shortDate: 'D-MM-YYYY'
};
const buckets = {
};
const app = {
    version: version.version,
}


module.exports = {
    app,
    endpoints,
    formats,
    buckets
};