//import 'bootstrap/dist/css/bootstrap.css';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faAngleDoubleDown,
    faFilePdf,
    faFlag,
    faCheck,
    faExclamationTriangle,
    faUser,
    faBuilding,
    faSignOutAlt,
    faSignInAlt,
    faFileSignature,
    faFileAlt,
    faSpinner,
    faTimes,
    faTimesCircle,
    faListAlt,
    faExclamationCircle,
    faCheckCircle,
    faUserCog,
    faFileDownload,
    faUpload
} from '@fortawesome/free-solid-svg-icons';

import './styles/main.css';
import MainLayout from './layouts/MainLayout';

library.add(
    faAngleDoubleDown,
    faFilePdf,
    faFlag,
    faCheck,
    faExclamationTriangle,
    faUser,
    faBuilding,
    faSignOutAlt,
    faSignInAlt,
    faFileSignature,
    faFileAlt,
    faSpinner,
    faTimes,
    faListAlt,
    faExclamationCircle,
    faCheckCircle,
    faUserCog,
    faUpload,
    faFileDownload,
    faTimesCircle);

export {
    MainLayout
};
