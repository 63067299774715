import React from 'react';
import {Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({component: Component, path, isLoggedIn, ...rest}) => {
    return (<Route
        exact
        path={path}
        render={() =>
            isLoggedIn ? (
                    <Component {...rest} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: window.location.pathname}
                    }}
                />
            )
        }
    />)
};

export default PrivateRoute;
