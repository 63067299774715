import {endpoints} from '../config';
import Axios from 'axios';
import jwt_decode from "jwt-decode";

const API_MANAGEMENT = endpoints.af_management_api.url;



class AuthService {
    static async login(payload) {
        try {
            const response = await Axios.post(`${API_MANAGEMENT}/auth`,payload);
            if(response.data.status === 'OK') {
                const jwtToken = response.data.data.token;
                const decodeJwtToken = jwt_decode(jwtToken);
                sessionStorage.setItem('logged', '1');
                sessionStorage.setItem('token', jwtToken);
                const sessionData = {
                    userId:decodeJwtToken.id,
                    role:decodeJwtToken.role,
                    email:decodeJwtToken.email}
                sessionStorage.setItem('session-data', JSON.stringify(sessionData));
                    return {
                        result:true,
                        userId:decodeJwtToken.id,
                        role:decodeJwtToken.role
                    };
            }else{
                sessionStorage.clear();
                return false;
            }
        }
        catch (error) {
            console.error(error);
        }
        return false;
    }

    static logout(){
        sessionStorage.clear();
        return true;
    }

    static async getAuthData() {
        const logged = sessionStorage.getItem('logged');
        const sessionData = sessionStorage.getItem('session-data');
        if (logged && sessionData) {
            return JSON.parse(sessionData);
        }
        return false;
    }

    static async getPassword(payload) {
        const response = await Axios.put(`${API_MANAGEMENT}/auth`,payload);
        return response;
    }

}
export default AuthService;