import React, {Component} from 'react';
import TableManagement from '../sharedComponents/TableManagement';
import apiBackend from '../../service/BackendApiService';

import alertify from 'alertifyjs'


class Supplier extends Component {

  constructor(props) {
    super(props);
    this.state ={
      userManagements:[]
    }
  }

  componentDidMount() {
    if(this.props.userId){
      this.getUSerManagement(this.props.userId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userId !== this.props.userId) {
      this.getUSerManagement(this.props.userId);
    }
  }
  
  async getUSerManagement(userId){
    if(!userId){
      return;
    }
    this.props.setIsLoading(true);
    try {
      const userManagement = await apiBackend.getSupplierAsignament(userId);
      if(userManagement.data.status === 'OK'){
        this.setState({
          userManagements: userManagement.data.data
        });
      }
    } catch (error) {
      alertify.error(error.toString());      
    }
    this.props.setIsLoading(false);
  }
  

  render() {
    return (
      <div className="App">
        <div>
          <TableManagement  rows={this.state.userManagements} userId={this.props.userId} role={this.props.role}/>
        </div>
      </div>
    );
  }
}

export default Supplier;
