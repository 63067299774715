import React, {Component} from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import AuthService from '../../service/AuthService';
import alertify from 'alertifyjs'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state={
            login:true,
            logged:false,
            passwordGET : queryString.parse(this.props.location.search).passwordGET || false,
            queryString : queryString.parse(this.props.location.search),
            redirect: this.props.location.state && this.props.location.state.from ? this.props.location.state.from : '/',
            userEmail: queryString.parse(this.props.location.search).userEmail || "",
            userRole: queryString.parse(this.props.location.search).userRole || null,
            userCode: queryString.parse(this.props.location.search).userCode || "",
        }
        this.handleClick = this.handleClick.bind(this);
    }

    async componentDidMount() {
        if(this.state.queryString !== queryString.parse(this.props.location.search)){
            this.setState({
                queryString : queryString.parse(this.props.location.search),
            });
        }
    }

    login = async () =>{
        this.props.setIsLoading(true);
        const payload = {
            "mail": this.state.userEmail,
            "role": this.state.userRole,
            "code": this.state.userCode
          }
        try {
            const resultLogin = await AuthService.login(payload);
            if(resultLogin && resultLogin.result){
                this.props.setIsAuthenticated(true);
                this.props.setUserRole(resultLogin.role)
                this.props.setUserId(resultLogin.userId)
                this.setState({
                    logged:true,
                    login:false
                });
            } else {
                this.props.setIsAuthenticated(false);
                alert("error login")
            }
        } catch (error) {
            alertify.error(error.toString())
        }
        this.props.setIsLoading(false);
    }

    getPassword = async () => {
        const payload = {
            "mail": this.state.userEmail,
            "role": this.state.userRole
          }
        this.props.setIsLoading(true);
        try {
            const getPasswordResponse = await AuthService.getPassword(payload);
            
            if(getPasswordResponse && getPasswordResponse.data.data === true){
                alertify.success("Su contraseña fue enviada al correo electronico.")
                this.setState({passwordGET:true})
            }else{
                alertify.error("Error con la informacion entregada.")
            }
        } catch (error) {
            alertify.error(error.toString())
        }
        this.props.setIsLoading(false);
        
    }

    handleChange (e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleClick() {
        this.setState( state => ({
            passwordGET:!state.passwordGET
        }));
    }

    render() {
        if (this.state.login) {
        return (
                <div className="loginDiv">
                    <h2>{ !this.state.passwordGET ? 'Solicitar' : 'Ingresar' } Contraseña</h2>

                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" className="form-control" placeholder="Ingrese Email" name="userEmail" value={this.state.userEmail} onChange={ (e) => this.handleChange(e) } />
                    </div>

                    <div className="form-group">
                        <label>Rol</label>
                        <select className="form-control" name="userRole" value={this.state.userRole} onChange={ (e) => this.handleChange(e) } >
                            <option >Seleccione Rol</option>
                            <option value="supplier">Proveedor</option>
                            <option value="approver">Aprobador</option>
                        </select>
                    </div>
                    { this.state.passwordGET ? 
                    <div className="form-group">
                        <label>Contraseña</label>
                        <input type="password" className="form-control" placeholder="Ingrese contraseña" name="userCode" value={this.state.userCode} onChange={ (e) => this.handleChange(e) } />
                    </div>
                    : ''}
                    { 
                        !this.state.passwordGET ? 
                            <div>
                                <button className="btn btn-primary btn-block" disabled={!this.state.userEmail || !this.state.userRole || this.props.isLoading} onClick={this.getPassword}>Solicitar Contraseña</button> 
                                <p className="forgot-password text-right">
                                    Ya tengo mi <span className="span-link" onClick={this.handleClick}>contraseña</span>
                                </p>
                            </div>
                        : 
                            <div>
                                <button className="btn btn-primary btn-block" disabled={!this.state.userEmail || !this.state.userRole || !this.state.userCode || this.props.isLoading} onClick={this.login}>Ingresar</button> 
                                <p className="forgot-password text-right">
                                    Solicitar <span className="span-link" onClick={this.handleClick}>contraseña</span>
                                </p>
                            </div>
                    } 
                    
                </div>
        )
        }else {
            return <Redirect to={this.state.redirect}/>
            
        }
    }
}

export default withRouter(Login);
