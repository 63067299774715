import React, { Component } from "react";
import TableDetail from './TableDetail';
import { withRouter } from 'react-router';
import alertify from 'alertifyjs'

import apiBackend from '../../service/BackendApiService';


class Detail extends  Component {

  constructor(props){
    super(props);
    this.state ={
      managementItems:[]
    }
    this.getAllItems();
  }

  componentDidMount() {
    if(this.props.userId){
      this.getAllItems(this.props.userId,this.props.match.params.managementId);
    }
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.userId && this.props.match.params.managementId && prevProps.userId !== this.props.userId) {
      this.getAllItems(this.props.userId,this.props.match.params.managementId);
    }
  }

  async getAllItems(userId,managementId){
    if(!userId || !managementId){
      return [];
    }
    try {
      this.props.setIsLoading(true);
      const managementItems = await apiBackend.getAsignamentItems(userId,managementId,this.props.role);
      if(managementItems.data.status === 'OK'){
        this.setState({
          managementItems: managementItems.data.data
        });
      }
    } catch (error) {
      alertify.error(error.toString());     
    }
    this.props.setIsLoading(false);
  }

  render() {
    return (
      <div>
        <TableDetail  rows={this.state.managementItems} userId={this.props.userId} assignametId={this.props.match.params.managementId} setIsLoading={this.props.setIsLoading} role={this.props.role}/>
      </div>
    );
  };
}

export default withRouter(Detail);